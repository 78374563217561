<template>
  <v-layout wrap justify-center align-center class="bg-main">
    <v-flex xs10 class="d-flex justify-center flex-column">
      <div class="d-flex justify-center">
        <img src="@/assets/image/logo-tnl.png" />
      </div>

      <div>
        <span class="text-input-tnl">เบอร์โทร</span>
        <v-text-field
          solo
          class="input-tnl"
          background-color="#FFFFFF"
          v-model="tel"
        ></v-text-field>
      </div>

      <div>
        <span class="text-input-tnl">รหัสผ่าน</span>
        <v-text-field
          solo
          class="input-tnl input-color-tnl"
          background-color="#FFFFFF"
          v-model="password"
          type="password"
          v-on:keyup.enter="login"
        ></v-text-field>
      </div>

      <div class="mt-16">
        <v-btn class="button-tnl" color="#59CB33" @click="login" dark>
          เข้าสู่ระบบ
        </v-btn>
      </div>

      <!-- <v-layout wrap justify-center>
        <v-flex xs12 class="d-flex justify-center">
          <div>
            <img src="@/assets/image/logo-tnl.png" />
          </div>
        </v-flex>
        <v-flex xs9 pt-10>
          <span class="text-input-tnl">เบอร์โทร</span>
          <v-text-field
            solo
            class="input-tnl"
            background-color="#FFFFFF"
            v-model="tel"
            @input="enforcePhoneFormat"
          ></v-text-field>
        </v-flex>
        <v-flex xs9>
          <span class="text-input-tnl">รหัสผ่าน</span>
          <v-text-field
            solo
            class="input-tnl input-color-tnl"
            background-color="#FFFFFF"
            v-model="password"
            type="password"
            v-on:keyup.enter="login"
          ></v-text-field>
        </v-flex>
      </v-layout> -->
    </v-flex>
    <!-- <v-flex xs12 class="d-flex align-center">
      <v-layout justify-center>
        <v-flex xs9>
          <v-btn class="button-tnl" color="#59CB33" @click="login" dark>
            เข้าสู่ระบบ
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex> -->
  </v-layout>
</template>

<script>
export default {
  name: "login",
  data: () => ({
    tel: "",
    password: "",
  }),
  methods: {
    enforcePhoneFormat() {
      let x = this.tel.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      this.tel = !x[2] ? x[1] : x[1] + "-" + x[2] + x[3];
    },
    login() {
      this.globalLoading();
      let data = {
        phone_number: this.tel,
        // phone_number: this.tel.substr(0, 3) + this.tel.substr(4),
        password: this.password,
      };
      this.$store.dispatch("auth/login", data).then((res) => {
        if (res) {
          this.$router.push("Main");
          this.globalHideLoading();
        } else {
          this.$swal({
            icon: "error",
            title: "",
            html: "เบอร์โทร หรือรหัสผ่านผิดพลาด",
            showConfirmButton: true,
          });
          // this.globalHideLoading();
        }
      });
    },
  },
};
</script>

<style scoped></style>
